import { getAuth, onAuthStateChanged, updateEmail } from "firebase/auth";
import { useUtilityStore } from "../zustand/utilityStores";
import {
  getFolders,
  getSexyAIUserSession,
  getSubscriberInfo,
  getTagsForUser,
  getUserSavedTags,
} from "./functions";
import { GoogleAuthProvider } from "firebase/auth";
import { logPro, logSignedIn } from "../analytics/googleAnalytics";
import { db } from "./setupFirebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useTagStore } from "../zustand/tagStore";
import { Tag } from "../common/tagDefinitions";
import { toast } from "react-hot-toast";

export const googleProvider = new GoogleAuthProvider();

const auth = getAuth();
export async function updateSubscriberInfo() {
  let membershipInfo = await getSubscriberInfo();
  useUtilityStore.getState().setSubscriberInfo(membershipInfo.patreon);
  useUtilityStore.getState().setCcInfo(membershipInfo.ccbill);
  useUtilityStore.getState().setEpochInfo(membershipInfo.epoch);
  useUtilityStore.getState().setDidFetchSubscriberInfo(true);

  // Free Pro
  if (membershipInfo.free_pro_until) {
    // If the date is in the future, set isPro to true
    const proDate = new Date(membershipInfo.free_pro_until);
    if (new Date(membershipInfo.free_pro_until) > new Date()) {
      useUtilityStore.getState().setFreeProUntil(proDate);
      logPro(true);
    }
  }

  // CC Bill
  if (membershipInfo.ccbill?.cc_next_renewal_date) {
    let nextRenewalDate = new Date(membershipInfo.ccbill.cc_next_renewal_date);
    // Add one day for timezone issues
    nextRenewalDate.setDate(nextRenewalDate.getDate() + 2);
  }

  // Server tells us if the user is pro
  if (membershipInfo.is_pro) {
    useUtilityStore.getState().setIsPro(true);
    logPro(true);
  } else {
    logPro(false);
  }
}

// Settings stored in the userSettings collection
interface UserSettings {
  isPrivateMode: boolean;
  filterSettings: string;
  imageEditType: "smart_edit" | "seed_edit";
  folderOrder: string;
}

// Load user settings from Firestore
export async function loadSettings(uid: string) {
  const settings = await getDoc(doc(db, "userSettings", uid));

  if (settings.exists()) {
    const data = settings.data() as UserSettings;

    // Load individual settings here
    if (data) {
      if (data.hasOwnProperty("isPrivateMode")) {
        useUtilityStore.getState().setIsPrivateMode(data.isPrivateMode);
      }

      // Load filter settings
      if (data.hasOwnProperty("filterSettings")) {
        useUtilityStore
          .getState()
          .setFilterSettings(JSON.parse(data.filterSettings));
      }

      // Image edit type
      if (data.hasOwnProperty("imageEditType")) {
        // Check if it's a valid value
        if (
          data.imageEditType === "smart_edit" ||
          data.imageEditType === "seed_edit"
        ) {
          useUtilityStore.getState().setImageEditType(data.imageEditType);
        }
      }

      // Folder order
      if (data.hasOwnProperty("folderOrder")) {
        // Parse the string into a map
        const folderOrderMap = JSON.parse(data.folderOrder);
        useUtilityStore.getState().setFolderOrderMap(folderOrderMap);
      }
    }
  }

  useUtilityStore.getState().setDidLoadSettings(true);
}

export async function saveSettings(key: string, value: any) {
  const uid = useUtilityStore.getState().uid;

  if (!uid) {
    return;
  }

  const docRef = doc(db, "userSettings", uid);

  const newData = {
    [key]: value,
  };
  await setDoc(docRef, newData, { merge: true });
}

onAuthStateChanged(auth, async (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User

    // get user raw data.
    const userRawData: any = user;

    const uid = user.uid;
    const email = user.email;
    const setUid = useUtilityStore.getState().setUid;
    setUid(uid);
    email && useUtilityStore.getState().setEmail(email);
    useUtilityStore.getState().setEmailVerified(user.emailVerified);
    const setIsSignedIn = useUtilityStore.getState().setIsSignedIn;
    setIsSignedIn(true);
    const setSignInFetched = useUtilityStore.getState().setSignInFetched;
    setSignInFetched(true);
    updateSubscriberInfo();
    logSignedIn(true);
    loadSettings(uid);
    
    getFolders().then((folders) => {
      useUtilityStore.getState().setFolders(folders);
    });

    // getSexyAIUserSession().then((sessionID: string) => {
    //   if (sessionID) {
    //     useUtilityStore.getState().setSexyAISessionID(sessionID);
    //   }
    // });

    const setPassword = useUtilityStore.getState().setPassword;
    setPassword(userRawData.reloadUserInfo.passwordHash ? userRawData.reloadUserInfo.passwordHash : 'tempPassword');

    const appendStudioTags = useTagStore.getState().appendStudioTags;

    // Load the Tag Artist's tags
    let combined: Tag[] = [];
    try {
      const studioTags = await getTagsForUser();
      combined.push(...studioTags);
    } catch (error) {}

    try {
      const savedTags = (await getUserSavedTags()).map((tag) => {
        return {
          ...tag,
          isSaved: true,
        };
      });

      combined.push(...savedTags);
    } catch (error) {}

    // Append because there may have been some locally resolved ones happening in parallel
    // TODO can the reverse order cause bugs?
    appendStudioTags(combined);
  } else {
    // User is signed out
    const setUid = useUtilityStore.getState().setUid;
    setUid("");
    const setIsSignedIn = useUtilityStore.getState().setIsSignedIn;
    setIsSignedIn(false);
    const setSignInFetched = useUtilityStore.getState().setSignInFetched;
    setSignInFetched(true);
    const setIsPro = useUtilityStore.getState().setIsPro;
    setIsPro(false);
    logSignedIn(false);
  }
});

export async function changeEmail(email: string) {
  const auth = getAuth();

  if (!auth.currentUser) {
    return;
  }

  return updateEmail(auth.currentUser, email)
    .then((data: any) => {
      console.log(data);
      toast("Email updated successfully!");
      window.location.reload();
    })
    .catch((error) => {
      if (error.code === "auth/requires-recent-login") {
        toast.error("Please log out, and log in again, to update your email.", {
          duration: 10000,
        });
        return;
      }

      if (error.code === "auth/email-already-in-use") {
        toast.error("Email already in use. Please use a different email.");
        return;
      }

      toast.error("Error updating email: " + error.message);
      return;
    });
}
