import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Import React Router's navigate
import { updateSubscriberInfo } from "../firebase/auth";
import { useUtilityStore } from "../zustand/utilityStores";

// interface GiftCardModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSubmit: () => void;
//   giftCardCode: string;
//   setGiftCardCode: React.Dispatch<React.SetStateAction<string>>;
// }

const GiftCards = () => {
  return (
    <div className="mt-5 p-4 w-full max-w-lg h-full flex flex-col items-center justify-center gap-2  rounded-lg border border-white/30 bg-slate-800">
      <h1 className="w-full py-2 text-center mt-0 uppercase font-bold text-yellow-400">NEW!</h1>
      <div className="w-full h-full flex flex-col md:flex-row gap-2">
        <div className="w-full h-full flex flex-col items-start justify-between gap-1 px-4 py-3 rounded-lg bg-gradient-to-tr from-pink-400 to-indigo-600">
          <div className="w-full flex gap-2 pb-3 flex items-center justify-between text-white/60">
            <h2 className="uppercase font-light text-base mt-0">
              g2a gift card
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-gift"
            >
              <rect x="3" y="8" width="18" height="4" rx="1" />
              <path d="M12 8v13" />
              <path d="M19 12v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7" />
              <path d="M7.5 8a2.5 2.5 0 0 1 0-5A4.8 8 0 0 1 12 8a4.8 8 0 0 1 4.5-5 2.5 2.5 0 0 1 0 5" />
            </svg>
          </div>
          <h3 className="text-2xl font-bold uppercase text-start">3 months</h3>
          <p className="text-sm italic text-white/70 pb-3">PRO Membership</p>
          <a className="px-3 py-1.5 rounded bg-white/20 text-sm" target="_blank" href="https://www.g2a.com/pornpenai-pro-membership-3-months-pornpenai-key-global-i10000508939001">
            Get Now
          </a>
        </div>
        <div className="w-full h-full flex flex-col items-start justify-between gap-1 px-4 py-3 rounded-lg bg-gradient-to-tr from-cyan-600 to-sky-300">
          <div className="w-full flex gap-2 pb-3 flex items-center justify-between text-white/60">
            <h2 className="uppercase font-light text-base mt-0">
              g2a gift card
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-gift"
            >
              <rect x="3" y="8" width="18" height="4" rx="1" />
              <path d="M12 8v13" />
              <path d="M19 12v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7" />
              <path d="M7.5 8a2.5 2.5 0 0 1 0-5A4.8 8 0 0 1 12 8a4.8 8 0 0 1 4.5-5 2.5 2.5 0 0 1 0 5" />
            </svg>
          </div>
          <h3 className="text-2xl font-bold uppercase">1 year</h3>
          <p className="text-sm italic text-white/70 pb-3">PRO Membership</p>
          <a className="px-3 py-1.5 rounded bg-white/20 text-sm" target="_blank" href="https://www.g2a.com/pornpenai-pro-membership-1-year-pornpenai-key-global-i10000508939002">
            Get Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default GiftCards;
