import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
} from "firebase/firestore";
import { db } from "./setupFirebase";
import { SearchResult } from "./searchUtils";
import { Result } from "../generator/tagGenerator";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { MembershipInfoResponse } from "../model/patreon";
import { IS_DEVELOPMENT, IS_TEST_CLOUD_FN } from "../test/SetupTest";
import { INPAINT_MODE } from "../zustand/historyStore";
import { Tag } from "../common/tagDefinitions";
import { useTagStore } from "../zustand/tagStore";
import { TagArtist } from "../common/userDefinitions";
import { useModelStore } from "../zustand/modelStore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const functions = getFunctions();

if (IS_DEVELOPMENT) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const API2_URL = "https://autoapi.cdn2.io/Pornpen/";

export interface SendPromptInput {
  prompt: Result;
  generator: string;
  colorHint?: string;
  isPrivate: boolean;
  isTest?: boolean;
  directorImg?: string;
  model?: string;
  animated?: boolean;
}

interface SendPromptOutput {
  id?: string;
  error?: string;
  existing_task_id?: string;
  numTasks?: number;
}

const submitPromptAuthFn = httpsCallable(functions, "submitPromptAuth");
export async function sendPrompt(data: SendPromptInput) {
  if (IS_TEST_CLOUD_FN) {
    data.isTest = true;
  }

  // TODO: Move to backend
  const generator = data.generator;
  const modelStore = useModelStore.getState();
  // Find the model for the generator
  const model = modelStore.models.find((m) => m.id === generator);
  if (model) {
    if (model.sdModel === "sdxl") {
      data.model = "sdxl";
    }
  }

  let response = await submitPromptAuthFn(data);
  return response.data as SendPromptOutput;
}

export function getUserSavedImagesDoc(imageId: string, userId: string) {
  return doc(db, "users", userId, "savedImages", imageId);
}

export async function saveImage(
  imageId: string,
  userId: string,
  isPrivate: boolean
) {
  if (!userId || !imageId) {
    return;
  }

  // Add saved image to a firestore collection
  await setDoc(getUserSavedImagesDoc(imageId, userId), {
    createDate: new Date(),
    isPrivate: isPrivate,
  });
}

export async function deleteSavedImage(imageId: string, userId: string) {
  if (!userId || !imageId) {
    return;
  }

  // Delete saved image from a firestore collection
  await deleteDoc(getUserSavedImagesDoc(imageId, userId));
}

export async function getImageSaveStatus(
  imageId: string,
  userId: string
): Promise<boolean> {
  if (!userId || !imageId) {
    return false;
  }

  // Add saved image to a firestore collection
  const docSnap = await getDoc(getUserSavedImagesDoc(imageId, userId));
  return docSnap.exists();
}

// Function to get all saved images for a user
// These are stored in a subcollection /users/uid/savedImages/imageId
// emptyButMore is true if the result had items, but they were deleted or missing.
// This might false trigger an empty case, but there may be more.
export async function getSavedImages(
  userId: string,
  lastDoc?: any,
  startDate?: Date | null
): Promise<[imageResults: SearchResult[], lastDoc: any, reachedEnd?: boolean]> {
  if (!userId) {
    return [[], null];
  }

  const docLimit = 30;

  let q = query(
    collection(db, "users", userId, "savedImages"),
    orderBy("createDate", "desc"),
    limit(docLimit)
  );

  if (lastDoc) {
    q = query(q, startAfter(lastDoc));
  } else if (startDate) {
    q = query(q, startAfter(startDate));
  }

  const querySnapshot = await getDocs(q);

  const savedImages = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      isPrivate: doc.data().isPrivate,
    };
  });

  // Loop through document ids and get the image urls
  // TODO do this server side to avoid many requests
  const imageUrls = await Promise.all(
    savedImages.map(async (savedImage) => {
      const imageId = savedImage.id;
      const docRef = savedImage.isPrivate
        ? doc(db, "users", userId, "private", imageId)
        : doc(db, "results", imageId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        return {
          imageUrl: null,
          imageId: imageId,
          isPrivate: savedImage.isPrivate,
        };
      }

      const data = docSnap.data();
      return {
        imageUrl: data?.image_url,
        imageId: imageId,
        isPrivate: savedImage.isPrivate,
        isUpscaled:
          data?.edit_type === "upscale2" || data?.edit_type === "upscale",
      };
    })
  );

  const imageResults = imageUrls.filter((x) => x.imageUrl) as SearchResult[];

  const reachedEnd = querySnapshot.docs.length < docLimit;

  return [
    imageResults,
    querySnapshot.docs[querySnapshot.docs.length - 1],
    reachedEnd,
  ];
}

export async function getPrivateImages(
  userId: string,
  lastDoc?: any,
  startDate?: Date | null
): Promise<[imageResults: SearchResult[], lastDoc: any, reachedEnd: boolean]> {
  if (!userId) {
    return [[], null, true];
  }

  let q = query(
    collection(db, "users", userId, "private"),
    orderBy("createDate", "desc"),
    limit(30)
  );

  if (lastDoc) {
    q = query(q, startAfter(lastDoc));
  } else if (startDate) {
    q = query(q, startAfter(startDate));
  }

  const querySnapshot = await getDocs(q);

  const imageResults = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      imageUrl: data.image_url,
      imageId: doc.id,
      isUpscaled: data.edit_type === "upscale2" || data.edit_type === "upscale",
    };
  });

  const reachedEnd = querySnapshot.docs.length < 30;

  return [
    imageResults,
    querySnapshot.docs[querySnapshot.docs.length - 1],
    reachedEnd,
  ];
}

const submitPatreonOAuth = httpsCallable(functions, "submitPatreonOAuth");

export async function submitPatreonOAuthCode(
  code: string,
  redirectUri: string
) {
  return await submitPatreonOAuth({ code, redirect_uri: redirectUri });
}

const getSubscriberInfoFn = httpsCallable(functions, "getSubscriberInfo");
export async function getSubscriberInfo(): Promise<MembershipInfoResponse> {
  let result = await getSubscriberInfoFn();
  // TODO Validate that the shape is correct
  return result.data as MembershipInfoResponse;
}

const submitUpscaleFn = httpsCallable(functions, "submitUpscale");
export async function submitUpscale(
  imageId: string,
  isPrivate: boolean,
  upscalerId: string,
  size: number
): Promise<{ id: string }> {
  let result = await submitUpscaleFn({
    imageId,
    isPrivate,
    isTest: IS_TEST_CLOUD_FN,
    upscalerId,
    size,
  });

  return result.data as { id: string };
}

interface SubmitPaintingResult {
  id: string;
}

const submitPaintingFn = httpsCallable(functions, "stagingSubmitPainting");
export async function submitPainting(
  imageId: string,
  promptData: Result,
  maskData: string,
  generator: string,
  maskMode: INPAINT_MODE,
  colorHint: string | null,
  isPrivate: boolean,
  enhancePaint: boolean,
  extraData?: any
) {
  let input: any = {
    imageId,
    promptData,
    generator,
    maskData,
    shouldInvert: maskMode === "keep",
    isMorph: maskMode === "morph",
    isPrivate,
    enhancePaint,
    isTest: IS_TEST_CLOUD_FN,
    ...extraData,
  };

  if (colorHint) {
    input.colorHint = colorHint;
  }

  const result = await submitPaintingFn(input);
  return result.data as SubmitPaintingResult;
}

const submitUncropFn = httpsCallable(functions, "stagingSubmitUncrop");

interface SubmitUncropResult {
  id: string;
}

export async function submitUncrop(
  imageId: string,
  scale: number,
  promptData: any,
  generator: string,
  offsetX: number,
  offsetY: number,
  isPrivate: boolean,
  model?: string
) {
  // SDXL uncrops are just inpaints
  if (model === "sdxl") {
    return await submitPainting(
      imageId,
      promptData,
      "",
      generator,
      "keep",
      null,
      isPrivate,
      false,
      {
        uncrop: true,
        scale: scale,
        offsetX: offsetX,
        offsetY: offsetY,
      }
    );
  }

  const result = await submitUncropFn({
    imageId,
    scale,
    promptData,
    generator,
    offsetX,
    offsetY,
    isPrivate,
    isTest: IS_TEST_CLOUD_FN,
  });
  return result.data as SubmitUncropResult;
}

const submitRecaptchaFn = httpsCallable(functions, "submitRecaptcha");
export async function submitRecaptcha(token: string) {
  await submitRecaptchaFn({ token });
}

interface SearchSqlResult {
  imageId: string;
  imageUrl: string;
  createTime: number;
}
const submitSearchFn = httpsCallable(functions, "stagingSearch");
export async function submitSearch(
  tags: string[],
  // Use an empty string to search across all generators
  generator: string,
  source: "make" | "search",
  startBeforeTimestamp?: number,
  hideEdits?: boolean,
  disableSort?: boolean
): Promise<SearchSqlResult[]> {
  let input: any = { tags, generator, source };

  if (startBeforeTimestamp) {
    input.startBeforeTimestamp = startBeforeTimestamp;
  }

  // Search across any generator
  if (!generator || generator === "all") {
    delete input.generator;
  }

  if (hideEdits) {
    input.hideEdits = true;
  }

  if (disableSort) {
    input.disableSort = true;
  }

  const result = await submitSearchFn(input);
  return result.data as SearchSqlResult[];
}

const submitEnhanceFn = httpsCallable(functions, "stagingSubmitEnhance");
export async function submitEnhance(
  imageId: string,
  generator: string,
  promptData: any,
  isPrivate: boolean,
  isUpscale: boolean
) {
  const result = await submitEnhanceFn({
    enhanceResultId: imageId,
    generator,
    promptData,
    isPrivate,
    isTest: IS_TEST_CLOUD_FN,
    isUpscale,
  });
  return result.data as any;
}

const submitFlagFn = httpsCallable(functions, "submitFlag");
export async function submitFlag(imageId: string) {
  await submitFlagFn({ imageId });
}

const deletePrivateImageFn = httpsCallable(functions, "deletePrivateImage");
export async function deletePrivateImage(imageId: string) {
  await deletePrivateImageFn({ imageId });
}

const rateImageFn = httpsCallable(functions, "rateImage");
export async function rateImage(
  imageId: string,
  rating: string,
  isVoting: boolean,
  isPrivate: boolean
) {
  await rateImageFn({ imageId, rating, isVoting, isPrivate });
}

interface ImageRating {
  rating: string;
}

const getImageRatingFn = httpsCallable(functions, "getImageRating");
export async function getImageRating(
  imageId: string,
  isPrivate: boolean
): Promise<ImageRating> {
  const result = await getImageRatingFn({ imageId, isPrivate });
  return result.data as ImageRating;
}

const addTagFn = httpsCallable(functions, "addTag");
export async function addTag(prompt: string, categoryId: string, name: string) {
  await addTagFn({ prompt, category: categoryId, name });
}

const getTagsForUserFn = httpsCallable(functions, "getTagsForUser");
export async function getTagsForUser(): Promise<Tag[]> {
  const result = await getTagsForUserFn();
  return result.data as Tag[];
}

const updateTagFn = httpsCallable(functions, "updateTag");
export async function updateTag(
  updates: {
    prompt?: string;
    category?: string;
    name?: string;
    description?: string;
    imageUrl?: string;
    onlyGenerators?: string[];
    disableForAnimated?: boolean;
  },
  tagId: string
) {
  await updateTagFn({ ...updates, tagId });
}

const submitTagFn = httpsCallable(functions, "submitTag");
export async function submitTag(tagId: string) {
  await submitTagFn({ tagId });
}

const getLiveTagsFn = httpsCallable(functions, "getLiveTagsCached");
let didFetchTags = false;
export async function getLiveTags(): Promise<void> {
  if (didFetchTags) {
    return;
  }
  didFetchTags = true;

  const result = await getLiveTagsFn();
  useTagStore.getState().setLiveTags(result.data as Tag[]);
}

const saveTagToProfileFn = httpsCallable(functions, "saveTagToProfile");
export async function saveTagToProfile(tagId: string) {
  await saveTagToProfileFn({ tagId });
}

const getUserSavedTagsFn = httpsCallable(functions, "stagingGetUserSavedTags");
export async function getUserSavedTags(): Promise<Tag[]> {
  const result = await getUserSavedTagsFn();
  return result.data as Tag[];
}

const saveUsernameFn = httpsCallable(functions, "saveUsername");
export async function saveUsername(username: string) {
  await saveUsernameFn({ username });
}

const getMyUsernameFn = httpsCallable(functions, "getMyUsername");
export async function getMyUsername(): Promise<string> {
  const result = await getMyUsernameFn();
  return result.data as string;
}

const getPublicTagDataFromIdsFn = httpsCallable(
  functions,
  "getPublicTagDataFromIds"
);
export async function getPublicTagDataFromIds(
  tagIds: string[]
): Promise<Record<string, Tag>> {
  const result = await getPublicTagDataFromIdsFn({ tagIds });
  return result.data as Record<string, Tag>;
}

const unpublishTagFn = httpsCallable(functions, "unpublishTag");
export async function unpublishTag(tagId: string) {
  await unpublishTagFn({ tagId });
}

const deleteAccountFn = httpsCallable(functions, "deleteAccount");
export async function deleteAccount() {
  await deleteAccountFn();
}

const deleteMultiplePrivateImagesFn = httpsCallable(
  functions,
  "deleteMultiplePrivateImages"
);
export async function deleteMultiplePrivateImages(imageIds: string[]) {
  await deleteMultiplePrivateImagesFn({ imageIds });
}

const claimGiftFn = httpsCallable(functions, "claimGift");
export async function claimGift(giftId: string) {
  await claimGiftFn({ giftId });
}

const createFolderFn = httpsCallable(functions, "stagingCreateFolder");
export async function createFolder(name: string) {
  await createFolderFn({ name });
}

export interface FolderSql {
  id: string;
  owner_uid: string;
  name: string;
  create_date: Date;
  thumbnail_url?: string;
}

export async function getFolders(): Promise<any> {

  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const idToken = await user.getIdToken();

    const headers = {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({
      data: null
    });

    const response: any = await fetch(
      API2_URL + "getFolders",
      {
        method: "POST",
        headers: headers,
        body: body,
      }
    );

    if (!response.ok) {
      return [];
    }

    const responseData = await response.json();

    return responseData.result as FolderSql[];
  }
  else {
    return [];
  }
}

const addImageToFolderFn = httpsCallable(functions, "stagingAddImageToFolder");
export async function addImageToFolder(
  imageId: string,
  folderId: string,
  isPrivate: boolean
) {
  await addImageToFolderFn({ imageId, folderId, isPrivate });
}

export async function getImagesInFolder(folderId: string, lastDate?: Date): Promise<any> {
  let input: any = { folderId };

  if (lastDate) {
    input = { ...input, cursor: lastDate };
  }

  // Return a Promise that resolves when user authentication state is available
  const auth = getAuth();

  return new Promise((resolve, reject) => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // User is authenticated, proceed with the API call
          const idToken = await user.getIdToken();

          const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          };

          const body = JSON.stringify({
            data: input
          });

          const response: any = await fetch(
            API2_URL + "getImagesInFolder",
            {
              method: "POST",
              headers: headers,
              body: body,
            }
          );

          if (!response.ok) {
            resolve([]); // No images, return an empty array
            return;
          }

          const responseData = await response.json();
          resolve(responseData.result as any[]); // Return the images
        } catch (error) {
          reject(error); // Reject the promise if an error occurs
        }
      } else {
        resolve([]); // No user is signed in, return an empty array
      }

      // Unsubscribe from auth state changes after handling
      unsubscribe();
    });
  });
}

const getFoldersForImageFn = httpsCallable(functions, "stagingGetFoldersForImage");
export async function getFoldersForImage(imageId: string) {
  const result = await getFoldersForImageFn({ imageId });
  return result.data as any[];
}

const deleteImagesFromFolderFn = httpsCallable(
  functions,
  "stagingDeleteImagesFromFolder"
);
export async function deleteImagesFromFolder(
  imageIds: string[],
  folderId: string
) {
  await deleteImagesFromFolderFn({ imageIds, folderId });
}

const deleteFolderFn = httpsCallable(functions, "stagingDeleteFolder");
export async function deleteFolder(folderId: string) {
  await deleteFolderFn({ folderId });
}

const updateFolderFn = httpsCallable(functions, "stagingUpdateFolder");
export async function updateFolder(
  folderId: string,
  name: string,
  thumbnailUrl: string
) {
  await updateFolderFn({ folderId, name, thumbnailUrl });
}

const adminGetSubmittedTagsFn = httpsCallable(
  functions,
  "adminGetSubmittedTags"
);
export async function adminGetSubmittedTags(): Promise<Tag[]> {
  const result = await adminGetSubmittedTagsFn();
  return result.data as Tag[];
}

const adminUpdateTagFn = httpsCallable(functions, "adminUpdateTag");
export async function adminUpdateTag(
  tagId: string,
  newStatus?: "live" | "rejected",
  rejectReason?: string,
  newPrompt?: string,
  isSilent?: boolean
) {
  let update: any = {
    tagId,
  };

  if (newStatus) {
    update.newStatus = newStatus;
  }

  if (rejectReason) {
    update.rejectReason = rejectReason;
  }

  if (newPrompt) {
    update.newPrompt = newPrompt;
  }

  if (isSilent) {
    update.isSilent = isSilent;
  }

  await adminUpdateTagFn(update);
}

export interface Flag {
  id: string;
  flag_create_date: any;
  flag_image_id: string;
  image_url: string;
  create_date: any;
  createDate?: any;
  edit_type: string;
}

const adminGetFlagsFn = httpsCallable(functions, "adminGetFlags");
export async function adminGetFlags(): Promise<Flag[]> {
  const result = await adminGetFlagsFn();
  return result.data as Flag[];
}

const adminReviewFlagFn = httpsCallable(functions, "adminReviewFlag");
export async function adminReviewFlag(
  action: "approve" | "delete_ancestors" | "delete_seed_ancestors",
  imageId: string
) {
  await adminReviewFlagFn({ action, imageId });
}

const adminGetTagInfoFn = httpsCallable(functions, "adminGetTagInfo");
export async function adminGetTagInfo(tagId: string) {
  if (!tagId) {
    return null;
  }

  const result = await adminGetTagInfoFn({ tagId });
  return result.data as Tag;
}

const adminLookupUserFn = httpsCallable(functions, "adminLookupUser");
export async function adminLookupUser(query: string) {
  const result = await adminLookupUserFn({ query });
  return result.data;
}

const adminGetTagArtistsFn = httpsCallable(functions, "adminGetTagArtists");
export async function adminGetTagArtists(): Promise<TagArtist[]> {
  const result = await adminGetTagArtistsFn();
  return result.data as TagArtist[];
}

const adminUpdateTagArtistFn = httpsCallable(functions, "adminUpdateTagArtist");
export async function adminUpdateTagArtist(
  tagArtistUid: string,
  newStatus: boolean
) {
  await adminUpdateTagArtistFn({ tagArtistUid, newStatus });
}

const adminGiveFreeProFn = httpsCallable(functions, "adminGiveFreePro");
export async function adminGiveFreePro(uid: string, timestamp: number) {
  await adminGiveFreeProFn({ uid, timestamp });
}

const upscaleToSDXLFn = httpsCallable(functions, "stagingUpscaleToSDXL");
export async function upscaleToSDXL(
  imageId: string,
  isPrivate: boolean,
  promptData: Result,
  generator: string,
  isTest?: boolean
): Promise<{ id: string }> {
  const result = await upscaleToSDXLFn({
    imageId,
    isPrivate,
    promptData,
    isTest,
    generator,
  });
  return result.data as { id: string };
}

const getTagStatsFn = httpsCallable(functions, "stagingGetTagStats");
export async function getTagStats(tagId: string) {
  const result = await getTagStatsFn({
    tagId,
  });

  return result.data as {
    numImagesLastSevenDays: number;
  };
}

export interface BatchImage {
  imageId: string;
  isPrivate: boolean;
}

const batchMoveImagesToFolderFn = httpsCallable(
  functions,
  "stagingBatchMoveImagesToFolder"
);
export async function batchMoveImagesToFolder(
  images: BatchImage[],
  destinationFolderIds: string[],
  options?: {
    removeFromSaved?: boolean;
  }
) {
  await batchMoveImagesToFolderFn({ images, destinationFolderIds, ...options });
}

interface ChatMessage {
  role: string;
  content: string;
}

const chatFn = httpsCallable(functions, "submitChat");
export async function submitChat(messages: ChatMessage[]) {
  const result = await chatFn({ messages });
  return result.data as string;
}

const chatMetadataFn = httpsCallable(functions, "submitChatMetadata");
export async function submitChatMetadata(
  messages: ChatMessage[],
  options?: {
    context?: string;
    resultId?: string;
    disableImage?: boolean;
    forceName?: string;
  }
) {
  const result = await chatMetadataFn({ messages, ...options });
  return result.data as {
    output: string;
    isImage?: boolean;
    tags?: string[];
  };
}

const bindCCBillFn = httpsCallable(functions, "bindCCBillSubscription");
export async function bindCCBillSubscription(
  subscriptionId: string,
  nextRenewalDate: string,
  userUid: string
) {
  await bindCCBillFn({ subscriptionId, nextRenewalDate, userUid });
}

// The function for getting Sexy.AI user session id from Firebase JWT
export async function getSexyAIUserSession(): Promise<any> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const idToken = await user.getIdToken();

    const headers = {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({
      data: null
    });

    const response: any = await fetch(
      // API2_URL + "getSexyAISessionID",
      "https://abby-api.cdn2.io/Pornpen/getSexyAISessionID",
      {
        method: "POST",
        headers: headers,
        body: body,
      }
    );

    if (!response.ok) {
      return "";
    }

    const responseData = await response.json();

    if (responseData.hasError) {
      return "";
    } else {
      return responseData.payload;
    }
  }
  else {
    return [];
  }
}
