import create from "zustand";
import produce from "immer";
import {
  MembershipInfo,
  MembershipInfoResponse,
} from "../model/patreon";
import { saveSettings } from "../firebase/auth";
import { FolderSql } from "../firebase/functions";
import {
  FilterSettings,
  loadFilterSettingsLocalStorage,
} from "../screens/Feed";

interface UtilityStore {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
  isSignedIn: boolean;
  setIsSignedIn: (isSignedIn: boolean) => void;
  signInRequired: boolean;
  setSignInRequired: (signInRequired: boolean) => void;
  uid: string;
  setUid: (uid: string) => void;
  signInFetched: boolean;
  setSignInFetched: (signInFetched: boolean) => void;
  isPro: boolean;
  setIsPro: (isPro: boolean) => void;
  isPatreonNoCCBill: boolean;
  setIsPatreonNoCCBill: (isPatreonNoCCBill: boolean) => void;
  subscriberInfo: MembershipInfo[];
  setSubscriberInfo: (subscriberInfo: MembershipInfo[]) => void;
  didFetchSubscriberInfo: boolean;
  setDidFetchSubscriberInfo: (didFetchSubscriberInfo: boolean) => void;
  freeProUntil: Date | null;
  setFreeProUntil: (freeProUntil: Date) => void;
  isPrivateMode: boolean;
  setIsPrivateMode: (isPrivateMode: boolean) => void;
  folders: FolderSql[];
  setFolders: (folders: FolderSql[]) => void;
  // If folder order is set, then key is folder id and value is the position (0 first)
  folderOrderMap: { [key: string]: number };
  setFolderOrderMap: (folderOrderMap: { [key: string]: number }) => void;
  ccInfo: MembershipInfoResponse["ccbill"] | null;
  setCcInfo: (ccInfo: MembershipInfoResponse["ccbill"] | null) => void;
  epochInfo: MembershipInfoResponse["epoch"] | null;
  setEpochInfo: (epochInfo: MembershipInfoResponse["epoch"] | null) => void;
  filterSettings: FilterSettings;
  setFilterSettings: (filterSettings: FilterSettings) => void;
  email: string;
  setEmail: (email: string) => void;
  emailVerified: boolean;
  setEmailVerified: (emailVerified: boolean) => void;
  imageEditType: "smart_edit" | "seed_edit";
  setImageEditType: (imageEditType: "smart_edit" | "seed_edit") => void;
  didLoadSettings: boolean;
  setDidLoadSettings: (didLoadSettings: boolean) => void;
  referenceImageUrl: string;
  setReferenceImageUrl: (referenceImageUrl: string) => void;
  password: string;
  setPassword: (password: string) => void;
  sexyAISessionID: string;
  setSexyAISessionID: (sessionID: string) => void;
}

export const useUtilityStore = create<UtilityStore>((set) => ({
  headerHeight: 44,
  setHeaderHeight: (headerHeight: number) => {
    set(
      produce((draft) => {
        draft.headerHeight = headerHeight;
      })
    );
  },
  referenceImageUrl: "",
  setReferenceImageUrl: (referenceImageUrl: string) => {
    set(
      produce((draft) => {
        draft.referenceImageUrl = referenceImageUrl;
      })
    );
  },
  isSignedIn: false,
  setIsSignedIn: (isSignedIn: boolean) => {
    set(
      produce((draft) => {
        draft.isSignedIn = isSignedIn;
      })
    );
  },
  signInRequired: false,
  setSignInRequired: (signInRequired: boolean) => {
    set(
      produce((draft) => {
        draft.signInRequired = signInRequired;
      })
    );
  },
  uid: "",
  setUid: (uid: string) => {
    set(
      produce((draft) => {
        draft.uid = uid;
      })
    );
  },
  signInFetched: false,
  setSignInFetched: (signInFetched: boolean) => {
    set(
      produce((draft) => {
        draft.signInFetched = signInFetched;
      })
    );
  },
  isPro: false,
  setIsPro: (isPro: boolean) => {
    set(
      produce((draft) => {
        draft.isPro = isPro;
      })
    );
  },
  isPatreonNoCCBill: false,
  setIsPatreonNoCCBill: (isPatreonNoCCBill: boolean) => {
    set(
      produce((draft) => {
        draft.isPatreonNoCCBill = isPatreonNoCCBill;
      })
    );
  },
  subscriberInfo: [],
  setSubscriberInfo: (subscriberInfo: MembershipInfo[]) => {
    set(
      produce((draft) => {
        draft.subscriberInfo = subscriberInfo;
      })
    );
  },
  didFetchSubscriberInfo: false,
  setDidFetchSubscriberInfo: (didFetchSubscriberInfo: boolean) => {
    set(
      produce((draft) => {
        draft.didFetchSubscriberInfo = didFetchSubscriberInfo;
      })
    );
  },
  freeProUntil: null,
  setFreeProUntil: (freeProUntil: Date) => {
    set(
      produce((draft) => {
        draft.freeProUntil = freeProUntil;
      })
    );
  },
  isPrivateMode: false,
  setIsPrivateMode: (isPrivateMode: boolean) => {
    saveSettings("isPrivateMode", isPrivateMode);
    set(
      produce((draft) => {
        draft.isPrivateMode = isPrivateMode;
      })
    );
  },
  folders: [],
  setFolders: (folders: FolderSql[]) => {
    set(
      produce((draft) => {
        // Sort folders and then set

        // Get the folder order map
        let folderOrderMap = draft.folderOrderMap;

        let sortedFolders = folders.slice();

        sortedFolders.sort((a: any, b: any) => {
          if (folderOrderMap[a.id] === undefined) {
            return -1;
          }

          if (folderOrderMap[b.id] === undefined) {
            return 1;
          }

          return folderOrderMap[a.id] - folderOrderMap[b.id];
        });

        draft.folders = sortedFolders;
      })
    );
  },
  folderOrderMap: {},
  setFolderOrderMap: (folderOrderMap: { [key: string]: number }) => {
    set(
      produce((draft) => {
        saveSettings("folderOrder", JSON.stringify(folderOrderMap));
        draft.folderOrderMap = folderOrderMap;

        // Sort the folders and set them. folders without an order at at the beginning
        let sortedFolders = draft.folders.slice();

        sortedFolders.sort((a: any, b: any) => {
          if (folderOrderMap[a.id] === undefined) {
            return -1;
          }

          if (folderOrderMap[b.id] === undefined) {
            return 1;
          }

          return folderOrderMap[a.id] - folderOrderMap[b.id];
        });

        draft.folders = sortedFolders;
      })
    );
  },
  ccInfo: null,
  setCcInfo: (ccInfo: MembershipInfoResponse["ccbill"] | null) => {
    set(
      produce((draft) => {
        draft.ccInfo = ccInfo;
      })
    );
  },
  epochInfo: null,
  setEpochInfo: (epochInfo: MembershipInfoResponse["epoch"] | null) => {
    set(
      produce((draft) => {
        draft.epochInfo = epochInfo;
      })
    );
  },
  filterSettings: loadFilterSettingsLocalStorage(),
  setFilterSettings: (filterSettings: FilterSettings) => {
    saveSettings("filterSettings", JSON.stringify(filterSettings));
    set(
      produce((draft) => {
        draft.filterSettings = filterSettings;
      })
    );
  },
  email: "",
  setEmail: (email: string) => {
    set(
      produce((draft) => {
        draft.email = email;
      })
    );
  },
  password: "",
  setPassword: (password: string) => {
    set(
      produce((draft) => {
        draft.password = password;
      })
    );
  },
  emailVerified: false,
  setEmailVerified: (emailVerified: boolean) => {
    set(
      produce((draft) => {
        draft.emailVerified = emailVerified;
      })
    );
  },
  imageEditType: "smart_edit",
  setImageEditType: (imageEditType: "smart_edit" | "seed_edit") => {
    set(
      produce((draft) => {
        saveSettings("imageEditType", imageEditType);
        draft.imageEditType = imageEditType;
      })
    );
  },
  didLoadSettings: false,
  setDidLoadSettings: (didLoadSettings: boolean) => {
    set(
      produce((draft) => {
        draft.didLoadSettings = didLoadSettings;
      })
    );
  },
  sexyAISessionID: "",
  setSexyAISessionID: (sessionID: string) => {
    set(
      produce((draft) => {
        draft.sexyAIUserSessionID = sessionID;
      })
    );
  }
}));
