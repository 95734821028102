import { Link, useNavigate, useSearchParams } from "react-router-dom";
import EnhanceImg from "../images/getpro/enhance.png";
import Doodle from "../images/getpro/doodle.jpeg";
import Uncrop from "../images/getpro/uncrop.png";
import SearchGrid from "../images/getpro/search_grid.png";
import { useUtilityStore } from "../zustand/utilityStores";
import DirectorImg from "../images/getpro/director.webp";
import Consistent from "../images/getpro/consistent.png";
import UpscaleExample from "../images/getpro/upscale.png";
import PayPalLogo from "../images/paypal.png";
import Cards from "../images/cards.png";
import Games from "../images/getpro/games.png";
import classNames from "classnames";
import { useEffect, useState } from "react";
import GiftCardModal from "../components/G2AGiftCardModal";
import GiftCards from "../components/GiftCards";
import { getAuth } from "firebase/auth";
import IAPIResponse from "../model/APIResponse";
import IEpochDynamicPricingAPIResponse from "../model/EpochDynamicPricingAPIResponse";
import { toast } from 'react-hot-toast';

export function GetProScreen() {
  const uid = useUtilityStore((state) => state.uid);
  const userEmail = useUtilityStore((state) => state.email);
  const userPassword = useUtilityStore((state) => state.password);

  const PaymentLink = `https://api.ccbill.com/wap-frontflex/flexforms/034af8de-0d67-45e8-8287-7fc1ea97401f?uid=${uid}&pid=${uid}`;
  const EpochPaymentLink = `https://wnu.com/secure/services/?api=join&pi_code=cwjura1p1449780&x_pp=${uid}&username=${userEmail}&password=${userPassword}`;

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility
  const [giftCardCode, setGiftCardCode] = useState("");

  const [searchParams] = useSearchParams();
  // Get the value of the 'code' query parameter
  const code = searchParams.get('code');

  const handleGiftCardSubmit = () => {
    // Handle the submission of the gift card code
    console.log("Gift Card Redeemed: ", giftCardCode);
    setIsModalOpen(false); // Close modal after submission
  };

  const emailVerified = useUtilityStore((state) => state.emailVerified);
  const isPro = useUtilityStore((state) => state.isPro);

  const testInfo = useUtilityStore((state) => {
    console.log(state);
    return state;
  });

  const auth = getAuth();

  const redirectToEpochPricingAPIResponseURL = async () => {
    const user = auth.currentUser

    if (user) {
      // Get the JWT token
      const idToken = await user.getIdToken();

      // Construct the Authorization header
      const headers = {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      };

      const resp: any = await fetch("https://cluster1api.cdn2.io/Payment/dreampen/epoch/GetEpochInitialPaymentRedirectURL", {
        method: 'POST',
        headers: headers
      });

      const respData: IAPIResponse<IEpochDynamicPricingAPIResponse> = await resp.json();

      console.log(respData);

      if (!respData.hasError) {
        const respPayload: IEpochDynamicPricingAPIResponse = respData.payload;

        if (respPayload.success) {
          window.location.href = respPayload.redirectURL;
        } else {
          toast.error(respPayload.error);
        }
      } else {
        toast.error(respData.message);
      }

    } else {
      toast.error("Please login!");
    }
  };

  // Take user to payment page if logged in, otherwise take them to login page
  let LinkEl = uid ? (
    <div
      className={classNames("flex flex-col mb-8", {
        "opacity-30 touch-none pointer-events-none": !emailVerified,
      })}
    >
      <sub className="py-4" style={{color: 'orange'}}><b>Please sign up to Epoch using the same email as your Pornpen account</b></sub>
      
      {
        code == 'intro20' ?
        (
          <button
            className="py-4 px-8 rounded-lg font-bold border bg-slate-600 text-2xl w-full"
            onClick={() => redirectToEpochPricingAPIResponseURL()}
          >
            Pay with Card (Epoch) (20% Discount)
          </button>
        ) :
        (
          <a
            className="py-4 px-8 rounded-lg font-bold border bg-slate-600 text-2xl w-full"
            href={EpochPaymentLink}
          >
            Pay with Card (Epoch)
          </a>
        )
      }
      
      <div className="flex justify-end pt-4 rounded-lg pr-4">
        <img src={Cards} alt="Cards" className="w-32" />
      </div>

      <GiftCardModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close modal
        onSubmit={handleGiftCardSubmit} // Handle submit
        giftCardCode={giftCardCode}
        setGiftCardCode={setGiftCardCode} // Update the gift card code
      />
      <a
        className="mt-5 py-4 px-10 rounded-lg font-bold border bg-slate-600 text-2xl cursor-pointer w-full"
        onClick={() => setIsModalOpen(true)} // Open modal
      >
        Redeem a Gift Card (G2A)
      </a>

      <div className="flex flex-col sm:flex-row justify-center items-center sm:items-start mt-2"> 
        <GiftCards/>
      </div>
    </div>
  ) : (
    <Link
      className="bg-yellow-500 py-5 px-8 text-lg rounded-lg max-w-md GradientButton font-bold border"
      to="/profile"
    >
      <span>Sign in to Get Pro Mode</span>
    </Link>
  );

  useEffect(() => {
    // If user is not pro, and url is pornpen.art, redirect to pornpen.ai
    if (!isPro && window.location.hostname === "pornpen.art") {
      window.location.href = "https://pornpen.ai/getpro";
    }
  }, [isPro]);

  return (
    <div className="text-white mt-4 text-lg text-center px-4">
      {isPro && (
        <div className="text-2xl animate-pulse">
          You already have Pro Mode, enjoy 😎
        </div>
      )}
      <div className="flex justify-center">
        <div className="mt-8 max-w-md text-center text-3xl">
          Join{" "}
          <span className="text-yellow-400 font-extrabold">
            thousands of subscribers
          </span>{" "}
          using Pro Mode!
        </div>
      </div>
      {!isPro && (
        <div className="mt-8 flex flex-row justify-center">{LinkEl}</div>
      )}
      {!emailVerified && uid && (
        <Link
          to="/profile"
          className="text-center mt-4 text-red-600 underline text-3xl"
        >
          Please verify your email before payment
        </Link>
      )}

      <div className="text-lg mt-4 font-bold">Pricing</div>
      <div className="flex flex-col sm:flex-row justify-center items-center sm:items-start mt-2">
        <div className="border border-white/30 rounded-lg mx-2 p-4 bg-slate-800 mb-2 w-64">
          <div className="text-lg font-bold text-left">Free Mode</div>
          <div className="text-left font-extrabold text-xl mb-4">Free</div>
          <ul className="list-disc list-inside text-left">
            <li>Free basic generations</li>
            <li>Slower generations</li>
            <li>Limited search results</li>
            <li>Watermarks</li>
          </ul>
        </div>
        <div className="border border-white/30 rounded-lg mx-2 p-4 bg-slate-800 mb-2 w-64">
          <div className="text-yellow-400 text-2xl font-bold text-left">
            Pro Mode
          </div>
          <div className="text-left font-extrabold text-xl">$15 / month</div>
          <div className="mb-4 text-sm">+tax, price varies by region</div>
          <ul className="list-disc list-inside text-left">
            <li>Unlimited generations</li>
            <li>Unlimited Games/Chat</li>
            <li>Consistent Characters</li>
            <li>Faster generations</li>
            <li>Private mode</li>
            <li>GIFs</li>
            <li>SDXL Generators</li>
            <li>Exclusive Tags</li>
            <li>No watermarks</li>
            <li>Commercial rights</li>
            <li>Higher quality</li>
            <li>Uncropping</li>
            <li>Upscaling</li>
            <li>Smart Edit</li>
            <li>Fix Details</li>
            <li>3D Posing</li>
            <li>Doodles</li>
            <li>Unlimited Search</li>
          </ul>
        </div>
      </div>
      <div className="mt-8 max-w-md text-center text-3xl m-auto font-extrabold text-yellow-400">
        Pro Features
      </div>
      <Section
        title="Faster and unlimited generations!"
        subtitle="Quickly generate unlimited images with a priority queue for Pro members! No credits or tokens required!"
      >
        <div className="border p-4 rounded-lg font-bold bg-slate-600">
          ⏱️ ~10s for 2 images
        </div>
      </Section>

      <Section
        title="Private mode + no watermark"
        subtitle="Generate images privately without watermarks! Private images are only visible to you."
      ></Section>

      <Section
        title="GIFs"
        subtitle="Generate short GIFs with your favorite tags!"
      >
        <video
          src="https://cdn.pornpen.ai/2f5aab8b-025b-439d-b3f2-de34b3a30b3a.mp4"
          autoPlay
          loop
          muted
        />
      </Section>

      <Section
        title="HD Generators"
        subtitle="Generate high quality images powered by SDXL!"
      >
        <img
          src="https://cdn.pornpen.ai/6fa1512b-2878-4461-a08c-6b82804127bf.jpg"
          alt="SDXL example"
        />
      </Section>

      <Section
        title="Consistent Characters"
        subtitle="Use the same character across multiple generations!"
      >
        <img src={Consistent} alt="Consistent character example" />
      </Section>

      <Section title="Uncropping" subtitle="Zoom out to reveal more!">
        <img src={Uncrop} alt="Uncrop" />
      </Section>

      <Section
        title="Upscaling"
        subtitle="Upscale an image by up to 4x to 2048x2048 resolution!"
      >
        <img src={UpscaleExample} alt="Upscale example" />
      </Section>

      <Section
        title="Fix details"
        subtitle="Enhance image details and faces with a single tap!"
      >
        <img src={EnhanceImg} alt="Enhance images" />
      </Section>
      <Section
        title="Unlimited Search"
        subtitle="Search through our database of 50+ million images!"
      >
        <img src={SearchGrid} alt="Search grid" className="rounded-lg" />
      </Section>
      <Section
        title="3D Posing"
        subtitle="Customize a 3D model and pose it however you want!"
      >
        <img src={DirectorImg} alt="Director" />
      </Section>
      <Section title="Doodles" subtitle="Sketch a doodle to guide generation!">
        <img src={Doodle} alt="Director" />
      </Section>
      <Section title="Games" subtitle="Unlimited AI chat and games!">
        <img src={Games} alt="Director" />
      </Section>
      {!isPro && (
        <div className="mt-8 flex flex-row justify-center">{LinkEl}</div>
      )}
    </div>
  );
}

function Section(props: {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className="mt-8 max-w-4xl mb-8 text-center flex flex-col justify-center items-center">
      <div className="text-2xl font-bold text-white">{props.title}</div>
      <div className="text-gray-300 max-w-lg">{props.subtitle}</div>
      <div className="mt-4 flex justify-center items-center flex-col">
        {props.children}
      </div>
    </div>
  );
}
