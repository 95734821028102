import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createFolder, getFolders } from "../firebase/functions";
import { AddFolderModal } from "./AddFolderModal";
import { useUtilityStore } from "../zustand/utilityStores";
var randomColor = require("randomcolor");

interface Folder {
  id: string;
  name: string;
  thumbnail_url?: string;
}

const FOLDER_STYLE =
  "aspect-square flex flex-col justify-center items-center rounded-md text-center text-lg break-words word-wrap p-4 hover:scale-105 hover:shadow-xl transition-all duration-200 ease-in-out";

export function FolderList() {
  const folders = useUtilityStore((state) => state.folders);
  const setFolders = useUtilityStore((state) => state.setFolders);

  const [showAddFolderModal, setShowAddFolderModal] = useState(false);

  const refreshFolders = () => {
    getFolders().then((folders) => {
      setFolders(folders);
    });
  };

  useEffect(() => {
    refreshFolders();
  }, []);

  const onClickCreateFolder = useCallback(() => {
    setShowAddFolderModal(true);
  }, []);

  const sortedFolders = [...folders].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  let grid = sortedFolders.map((folder) => {
    let color = randomColor({ luminosity: "dark", seed: folder.id });

    const style = folder.thumbnail_url
      ? {
          backgroundImage: `url(${folder.thumbnail_url})`,
          backgroundSize: "contain",
        }
      : {
          backgroundColor: color,
        };

    return (
      <Link
        to={`/folder/${folder.id}`}
        key={folder.id}
        className={FOLDER_STYLE}
        style={style}
      >
        <div className="break-all px-4 py-2 rounded-lg bg-black/60">
          {folder.name}
        </div>
      </Link>
    );
  });

  grid.unshift(
    <Link
      to="/private"
      className={FOLDER_STYLE + " bg-gray-800 cursor-pointer select-none"}
      key={"#private"}
    >
      <div className="text-6xl">🔒</div>
      <div>Private</div>
    </Link>
  );

  grid.unshift(
    <Link
      to="/saved"
      className={FOLDER_STYLE + " bg-green-600 cursor-pointer select-none"}
      key={"#saved"}
    >
      <div className="text-6xl">♥️</div>
      <div>Saved</div>
    </Link>
  );

  grid.unshift(
    <div
      className={FOLDER_STYLE + " bg-blue-700 cursor-pointer select-none"}
      onClick={onClickCreateFolder}
      key="#new-folder"
    >
      <div className="text-6xl">+</div>
      <div>New Folder</div>
    </div>
  );

  return (
    <div className="text-white grid grid-cols-fill-200 auto-rows-auto w-full gap-2 justify-center">
      {grid}
      <AddFolderModal
        showModal={showAddFolderModal}
        setShowModal={setShowAddFolderModal}
        addFolder={createFolder}
        refreshFolders={refreshFolders}
      />
    </div>
  );
}
