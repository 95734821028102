import { Button } from "flowbite-react";
import React, { useState } from "react";
import { ModalContainer } from "./ModalContainer";

interface SexyAIOfferModalProps {
  sexyAISessionID: string;
}

export default function SexyAIOfferModal({ sexyAISessionID }: SexyAIOfferModalProps) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const accept = () => {
    localStorage.setItem("sexySessionID", sexyAISessionID);
  };

  const handleAccept = () => {
    accept();
    window.open("https://beta.sexy.ai/?sid=" + sexyAISessionID, "_blank");
    setShow(false);
  };

  const footer = (
    <>
      <Button onClick={handleAccept}>Start Enjoying Sexy.AI Now</Button>
      <Button color="gray" onClick={handleClose}>
        Close
      </Button>
    </>
  );
  console.log("Modal is visible:", show);

  return (
    <React.Fragment>
      {show && <div className="fixed inset-0 z-50 backdrop-blur-xl"></div>}
      <ModalContainer
        title="Thank you for using Pornpen.ai Pro Subscription!"
        showModal={show}
        setShowModal={setShow}
        footer={footer}
      >
        <div className="space-y-6">
          <p className="text-base leading-relaxed dark:text-gray-100">
            We are excited to introduce you to our sibling product, Sexy.AI — a wonderful NSFW
            images and video generator. You will receive a free Sexy.AI Pro Tier
            1 account, so enjoy the experience and happy generating!
          </p>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
}
